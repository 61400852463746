.rec-arrow,
.rec-pagination {
    display: none !important;
}

.rec-slider-container {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}